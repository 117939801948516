import { Divider, ListItemButton, ListItemText } from "@mui/material";

import notFoundIcon from "../../../../constants/icons/ic_data_not_found.svg";

import { ListSearchProp } from "./type";
import {
  Container,
  ContainerNtoFound,
  ContainerStyleList,
  StyleList,
  TextNotFound,
} from "./style";
import { useCallback } from "react";
import Loading from "../../../common/Loading";
import { GRAY_565656 } from "../../../../constants/colors";

const ListSearch = (prop: ListSearchProp) => {
  const { list, bondType, onClickItem, eventSearch, scrollProp = {} } = prop;

  const onScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      eventSearch?.target.blur();
      const {
        isFetched,
        isLoading,
        hasNextPage,
        onScroll: onScrollProp,
      } = scrollProp;
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      const bottom = scrollHeight - scrollTop - (clientHeight - 50) <= 100;

      if (bottom && isFetched && hasNextPage && !isLoading) {
        e.stopPropagation();
        onScrollProp?.();
      }
    },
    [scrollProp, eventSearch]
  );

  return (
    <>
      {list.length === 0 && (
        <ContainerNtoFound>
          <img
            width={bondType === "SB" ? "300" : "221"}
            height="144"
            src={notFoundIcon}
            alt="not found"
          />
          <TextNotFound color={GRAY_565656}>
            {bondType === "SB"
              ? "ไม่พบพันธบัตรที่คุณค้นหาในตลาดรอง"
              : "ไม่พบหุ้นกู้ที่คุณค้นหาในตลาดรอง"}
          </TextNotFound>
        </ContainerNtoFound>
      )}
      {list.length > 0 && (
        <ContainerStyleList>
          <StyleList onScroll={onScroll}>
            {list.map((data, index) => {
              const { name, description } = data;
              return (
                <Container key={name + String(index)}>
                  <ListItemButton disableTouchRipple>
                    <ListItemText
                      primary={name}
                      secondary={description}
                      onClick={onClickItem.bind(null, name)}
                    />
                  </ListItemButton>

                  {index === list.length - 1 ? null : (
                    <Divider variant="middle" />
                  )}
                </Container>
              );
            })}
            <Loading loading={scrollProp?.isLoading || false} />
          </StyleList>
        </ContainerStyleList>
      )}
    </>
  );
};

export default ListSearch;
