import { StyledChip } from "./style";
import { ChipProps } from "./type";

const Chip = (props: ChipProps) => {
  const { label, height = 27, fontSize = "1.125rem", padding = "1rem" } = props;
  return (
    <StyledChip
      label={label}
      height={height}
      fontSize={fontSize}
      padding={padding}
    />
  );
};

export default Chip;
