import { Avatar } from "@mui/material";
import DefaultImage from "@mui/icons-material/BrokenImage";
import DefaultImageSavingBond from "../../constants/images/logo-saving.png";

import Chip from "../common/Chip";
import Text from "../common/Text";

import { BLACK_212121, GRAY_838383 } from "../../constants/colors";
import {
  StyledAvartarContainer,
  StyledCard,
  StyledCardContentContainer,
  StyledCardFooter,
  StyledBondDescriptionText,
  StyledBondContentContainer,
  StyledFooterLayout1,
  StyledFooterLayout2,
} from "./style";
import { BondCardProps } from "./type";
import { useScreen } from "../../utils/responsive-helper";

const BondCard = (props: BondCardProps) => {
  const { title, description, interestRate, imageUrl, rateType, bondType } =
    props;

  const { isMobileSmall } = useScreen();

  return (
    <StyledCard>
      <StyledCardContentContainer>
        <StyledAvartarContainer>
          {bondType === "SB" ? (
            <Avatar
              sizes={"32"}
              variant="square"
              src={DefaultImageSavingBond}
            />
          ) : imageUrl ? (
            <Avatar sizes={"32"} variant="square" src={imageUrl} />
          ) : (
            <Avatar sizes={"32"} variant="square">
              <DefaultImage />
            </Avatar>
          )}
        </StyledAvartarContainer>
        <StyledBondContentContainer>
          <Text color={BLACK_212121} weight={600}>
            {title}
          </Text>
          <StyledBondDescriptionText
            lineHeight="1.311rem"
            size="0.875rem"
            color={GRAY_838383}
          >
            {description}
          </StyledBondDescriptionText>
        </StyledBondContentContainer>
      </StyledCardContentContainer>
      <StyledCardFooter>
        <StyledFooterLayout1>
          <Text weight={600} size={isMobileSmall ? "0.875rem" : "1rem"}>
            {bondType === "SB" ? "ดอกเบี้ยต่อปี" : "อัตราดอกเบี้ยหน้าตั๋ว"}
          </Text>
          {rateType === "03" && (
            <Text size={"0.875rem"} color={GRAY_838383}>
              แบบขั้นบันได
            </Text>
          )}
        </StyledFooterLayout1>
        <StyledFooterLayout2>
          <Chip
            label={interestRate}
            fontSize={isMobileSmall ? "0.718rem" : "1.125rem"}
          />
        </StyledFooterLayout2>
      </StyledCardFooter>
    </StyledCard>
  );
};

export default BondCard;
