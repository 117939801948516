import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "../../utils/helper";
import Search from "../../components/Search";
import BondCard from "../../components/BondCard";
import notFoundIcon from "../../constants/icons/ic_data_not_found.svg";
import {
  Container,
  ContainerHeader,
  ContainerBody,
  ContainerHr,
  ContainerNotFound,
  Hr,
  TextNotFound,
  ContainerBondCard,
} from "./style";
import Overall from "../../components/Overall";
import BondDetail from "../../components/BondDetail";
import ChartHistory from "../../components/ChartHistory";

import { ButtonGroupValue } from "../../components/ChartHistory/type";
import { useGetBond, useSearchBond } from "../../services/home/home-query";
import {
  OverallResponse,
  SearchSymbolPagingResponse,
  SymbolType,
} from "../../services/home/home-types";
import { ItemSearchListType } from "../../components/Search/component/ListSearch.tsx/type";
import { ButtonGroupValueType } from "../../components/common/Buttongroup/type";
import lodash from "lodash";

const mapSearchDataApiToComponent = (
  searchBond: SearchSymbolPagingResponse[]
) => {
  const result = searchBond?.map(
    (responseWithpaging: SearchSymbolPagingResponse) => {
      const { data } = responseWithpaging;
      return data?.map((d: SymbolType) => {
        const { thaiSymbol, nameTh } = d;
        return { name: thaiSymbol, description: nameTh };
      });
    }
  );

  return lodash.flatten(result);
};
const getButtonGroupDefaultValue = (): ButtonGroupValueType[] => {
  return [
    {
      label: "1 สัปดาห์",
      value: ButtonGroupValue.ONE_WEEK,
      isDefault: true,
      isDisable: false,
    },
    {
      label: "1 เดือน",
      value: ButtonGroupValue.ONE_MONTH,
      isDefault: false,
      isDisable: false,
    },
    {
      label: "3 เดือน",
      value: ButtonGroupValue.THREE_MONTH,
      isDefault: false,
      isDisable: false,
    },
  ];
};

const Home = () => {
  const router = useRouter();

  const [data, setData] = useState<any>();
  const [q, setQ] = useState("");
  const [thaiSymbol, setThaiSymbol] = useState(router.query.symbol || "");
  const [isLoadingBond, setIsLoadingBond] = useState(true);
  const [isDataAvailable, setIsDataAvailable] = useState(
    thaiSymbol.length > 0 ? true : false
  );
  const [showComponents, setShowComponents] = useState(true);
  const [buttonGroupValue, setButtonGroupValue] = useState<
    ButtonGroupValueType[]
  >(getButtonGroupDefaultValue());
  const [period, setPeriod] = useState<string | undefined>(
    ButtonGroupValue.ONE_MONTH
  );
  const [isLodingButtonGroupValue, setIsLodingButtonGroupValue] =
    useState(false);

  const [valueSearch, setValueSearch] = useState<
    ItemSearchListType[] | undefined
  >([]);

  const bondType = useMemo(() => {
    const paths = router.pathname.split("/");
    const currentbondType = paths[1];
    return currentbondType.toUpperCase();
  }, [router]);

  const {
    data: searchBond,
    fetchNextPage,
    isFetched,
    isFetchingNextPage,
    hasNextPage,
  } = useSearchBond({ q, bondType });
  const { mutate: getBond, isLoading } = useGetBond();

  useEffect(() => {
    const newData = mapSearchDataApiToComponent(searchBond?.pages || []);
    setValueSearch(newData);
  }, [searchBond]);

  useEffect(() => {
    setIsDataAvailable(true);

    if (period && thaiSymbol) {
      setIsLoadingBond(true);
      getBond(
        {
          period,
          thaiSymbol,
          bondType,
        },
        {
          onSuccess: (data) => {
            if (data) {
              setData(data);
              setShowComponents(true);
            } else {
              setShowComponents(true);
              setIsDataAvailable(false);
              setIsLoadingBond(false);
            }
          },
          onError: (response) => {
            setIsDataAvailable(false);
            setIsLoadingBond(false);
            console.log("response error", response);
          },
        }
      );
    } else {
      setShowComponents(true);
      setIsDataAvailable(false);
      setIsLoadingBond(false);
    }
  }, [
    getBond,
    period,
    thaiSymbol,
    setShowComponents,
    setIsDataAvailable,
    setData,
    bondType,
    setIsLoadingBond,
  ]);

  useEffect(() => {
    let indexDefault: number = -1;
    let currentButtonGroupValue = getButtonGroupDefaultValue();
    setIsLodingButtonGroupValue(true);

    data?.overallAvg.forEach((data: OverallResponse, index: number) => {
      const { value } = data;

      if (!value) {
        currentButtonGroupValue[index].isDisable = true;
      }

      if (value && (index < 2 || indexDefault === -1)) {
        indexDefault = index;
      }

      if (value && index === 2 && indexDefault === -1) {
        indexDefault = index;
      }
    });

    if (indexDefault !== -1) {
      currentButtonGroupValue[indexDefault].isDefault = true;
    } else {
      indexDefault = 0;
    }

    setPeriod(currentButtonGroupValue[indexDefault].value);
    setButtonGroupValue(currentButtonGroupValue);
    setIsLodingButtonGroupValue(false);
  }, [
    data?.overallAvg,
    setButtonGroupValue,
    setPeriod,
    setIsLodingButtonGroupValue,
  ]);

  useEffect(() => {
    if (!isLoading && !isLodingButtonGroupValue) {
      setIsLoadingBond(false);
    }
  }, [isLoading, isLodingButtonGroupValue, setIsLoadingBond, data]);

  const scrollProp = useMemo(() => {
    return {
      hasNextPage,
      isLoading: isFetchingNextPage,
      isFetched,
      onScroll: fetchNextPage,
    };
  }, [hasNextPage, isFetched, fetchNextPage, isFetchingNextPage]);

  const isShowContent = useMemo(() => {
    return showComponents && isDataAvailable;
  }, [showComponents, isDataAvailable]);

  const isShowNotFound = useMemo(() => {
    return showComponents && !isDataAvailable;
  }, [showComponents, isDataAvailable]);

  const onChangeShowComopnent = useCallback(
    (value: boolean) => {
      setShowComponents(value);
      setIsDataAvailable(true);

      if (!data && thaiSymbol.length === 0) {
        setIsDataAvailable(false);
      }
    },
    [setShowComponents, setIsDataAvailable, data, thaiSymbol]
  );

  const onSelectedChartHistory = useCallback(
    (period: string) => {
      setPeriod(period);
    },
    [setPeriod]
  );

  const onValueChange = useCallback(
    (selectedValue: string) => {
      setThaiSymbol(selectedValue);
      setPeriod(ButtonGroupValue.ONE_MONTH);
    },
    [setThaiSymbol]
  );

  const onSearchChange = useCallback(
    (value: string) => {
      setQ(value);
    },
    [setQ]
  );

  return (
    <Container isSearch={!isShowContent}>
      <ContainerHeader>
        <Search
          valueSearch={valueSearch || []}
          onSearch={onSearchChange}
          onSelected={onValueChange}
          scrollProp={scrollProp}
          setShowComponents={onChangeShowComopnent}
          showComponents={showComponents}
          bondType={bondType}
        />
      </ContainerHeader>

      {isShowContent && (
        <>
          <ContainerBondCard>
            <BondCard
              title={data?.thaiSymbol || ""}
              description={data?.nameTh || ""}
              interestRate={data?.couponRate || ""}
              rateType={data?.couponPayment || ""}
              imageUrl={data?.issuerImageUrl}
              bondType={bondType}
            />
          </ContainerBondCard>
          <ContainerBody>
            <ChartHistory
              isLoading={isLoadingBond}
              buttonGroupValue={buttonGroupValue}
              data={data?.yieldPrices}
              onSelected={onSelectedChartHistory}
            />
            <ContainerHr>
              <Hr />
            </ContainerHr>
            <Overall values={data?.overallAvg || []} />
            <BondDetail detail={data} bondType={bondType} />
          </ContainerBody>
        </>
      )}
      {isShowNotFound ? (
        <ContainerNotFound>
          <img
            width={bondType === "SB" ? "300" : "221"}
            height="144"
            src={notFoundIcon}
            alt="not found"
          />
          <TextNotFound>
            {bondType === "SB"
              ? "ไม่พบพันธบัตรที่คุณค้นหาในตลาดรอง"
              : "ไม่พบหุ้นกู้ที่คุณค้นหาในตลาดรอง"}
          </TextNotFound>
        </ContainerNotFound>
      ) : null}
    </Container>
  );
};

export default Home;
