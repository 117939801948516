import { useMemo, useState, useEffect, useCallback } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";

import Text from "../Text/index";
import {
  BLACK_212121,
  BLUE_025BB7,
  GRAY_E0E0E0,
  GRAY_F2F4F7,
  GRAY_565656,
} from "../../../constants/colors";
import { ChartDataType, ChartProps, DataArray, DateArray } from "./type";
import {
  StyledChartDiv,
  StyledEchart,
  StyledLayoutHeader,
  StyledLayoutIconReset,
  StyledNoDataImage,
  StyledTypography,
  StyledNoDataContainer,
  StyledLayoutLoading,
} from "./style";
import image from "../../../constants/images/empty_state.svg";
import dayjs from "dayjs";

const createChartData = (data) => {
  const dataArray: DataArray = data?.map((d) => d.avgYield);
  const dateArray: DateArray = data?.map((d) => d.settlementDate);
  return { dataArray, dateArray };
};

const createThaiDateFormat = (data: DateArray) => {
  const thaiMonth = [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค.",
  ];
  return data?.map((data) => {
    const date = dayjs(data);
    const isValid = date.isValid();
    return isValid
      ? `${date.format("DD")} ${thaiMonth[date.month()]} ${
          (date.year() + 543) % 100
        }`
      : null;
  });
};

const calculateLimitedYaxisValue = (data) => {
  return Math.floor(Math.max(...data)) + 2;
};

const Chart = (props: ChartProps) => {
  const { data, title, interval = "auto", isLoading } = props;
  const [curData, setCurData] = useState(data);

  const [curInterval, setCurInterval] = useState(interval);
  const [isAnimation, setIsAnmiation] = useState(true);

  useEffect(() => {
    setCurData(data);
  }, [data, setCurData]);

  useEffect(() => {
    if (curData.length !== 0) {
      setCurInterval(interval);
    }
  }, [curData, interval, setCurInterval]);

  const chartData = useMemo(() => {
    const object = createChartData(curData);
    const curXAxis = createThaiDateFormat(object.dateArray);

    return {
      xAxis: curXAxis,
      yAxis: object.dataArray,
      limitedYaxisValue: calculateLimitedYaxisValue(object.dataArray),
    };
  }, [curData]);

  const onClickResetChart = useCallback(() => {
    setIsAnmiation(true);
    const newData =
      data?.map((x: ChartDataType) => {
        return x;
      }) || [];

    setCurData(newData);
  }, [data, setCurData, setIsAnmiation]);

  const onScrollMouse = useCallback(
    (e) => {
      setIsAnmiation(false);
      if (e.type === "datazoom") {
        const isZoomStart =
          e.batch.length > 2 &&
          e.batch[0].start === 0 &&
          e.batch[0].end === 100 &&
          e.batch[1].start === 0 &&
          e.batch[1].end === 100;
        if (isZoomStart) {
          setCurInterval(interval);
        } else {
          setCurInterval("auto");
        }
      }
    },
    [setIsAnmiation, setCurInterval, interval]
  );

  const option = {
    tooltip: {
      trigger: "item",
      backgroundColor: GRAY_E0E0E0,
      borderWidth: 0,
      position: "top",
      axisPointer: {},
      textStyle: {
        fontFamily: "Kanit",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 10,
        lineHeight: 15,
        color: BLACK_212121,
      },
      extraCssText: `
      text-align: center;
    `,
      formatter: "{b}<br/>{c} %",
      shadowBlur: 0,
      shadowColor: "transparent",
      fontSize: "0.625rem",
      valueFormatter: (tooltipValue) => tooltipValue + "%", // formatting tooltips value to 2 decimal and put "%" in the end of value
    },
    grid: {
      left: "8",
      right: "20",
      bottom: "3%",
      top: "3%",
      containLabel: true,
      clipOverflow: false,
    },
    xAxis: {
      type: "category",
      boundaryGap: chartData.yAxis.length < 7,
      animation: false,
      data: chartData.xAxis,
      axisLabel: {
        interval: curInterval,
        hideOverlap: false,
        margin: 15,
        formatter: (chartDataXaxis) => {
          const result = chartDataXaxis.split(" ").slice(0, 2).join(" "); // remove year on chartData.xAxis
          return result;
        },
        align: "left",
        verticalAlign: "middle",
        textStyle: {
          fontSize: "0.75rem",
          fontFamily: "Kanit",
          fontWeight: "400",
          color: function () {
            return GRAY_565656;
          },
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      position: "right",
      animation: false,
      interval: 1,
      className: "y-axis-label",
      axisLabel: {
        formatter: (chartDataYaxis) => `${chartDataYaxis.toFixed(2)}%`, // fix chartDataYaxis to 1 decimal and add % string in the end of data
        padding: [0, 0, 0, 8],
        textStyle: {
          fontSize: "0.75rem",
          fontFamily: "Kanit",
          fontWeight: "400",
          color: function () {
            return GRAY_565656;
          },
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: GRAY_F2F4F7,
          width: "1",
        },
      },
      min: 0,
      max: chartData.limitedYaxisValue,
    },

    dataZoom: [
      {
        id: "dataZoomY",
        type: "inside",
        throttle: 150,
        minSpan: 40,
        orient: "vertical",
        show: true,
        filterMode: "filter",
      },
      {
        id: "dataZoomX",
        type: "inside",
        filterMode: "filter",
        orient: "horizontal",
        minValueSpan: 1,
      },
    ],
    series: [
      {
        type: "line",
        connectNulls: true,
        data: chartData.yAxis,
        animation: isAnimation,
        symbolSize: 12,
        showSymbol: true,
        smooth: true,
        lineStyle: {
          color: BLUE_025BB7,
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: BLUE_025BB7,
              },
              {
                offset: 1,
                color: "rgba(2, 91, 183, 0)",
              },
            ],
            globalCoord: false,
          },
          opacity: 0.1,
          style: {
            mixBlendMode: "multiply",
          },
        },
      },
    ],
  };
  return (
    <>
      <StyledLayoutHeader>
        <Text weight={600}>{title}</Text>
        {!isLoading && (
          <StyledLayoutIconReset>
            <RefreshIcon onClick={onClickResetChart} />
          </StyledLayoutIconReset>
        )}
      </StyledLayoutHeader>
      <StyledChartDiv>
        {isLoading ? (
          <StyledLayoutLoading>
            <CircularProgress />
          </StyledLayoutLoading>
        ) : curData.length === 0 ? (
          <StyledNoDataContainer>
            <StyledNoDataImage width="220" height="132" src={image} />
            <StyledTypography>ไม่พบข้อมูลย้อนหลัง</StyledTypography>
          </StyledNoDataContainer>
        ) : (
          <StyledEchart
            key={Date.now().toString()}
            option={option}
            onEvents={{ dataZoom: onScrollMouse }}
          />
        )}
      </StyledChartDiv>
    </>
  );
};

export default Chart;
