import { useState, useCallback } from "react";
import { InputAdornment, IconButton } from "@mui/material";

import searchIcon from "../../constants/icons/ic_search.svg";
import clearIcon from "../../constants/icons/ic_close-circle.svg";
import Text from "../common/Text";
import ListSearch from "./component/ListSearch.tsx";

import { StyleSearch, StyleSearchContainer, StyleLink, FlexRow } from "./style";
import { SearchProps } from "./type";
import ReactGA from "react-ga";
declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        historicalChart: {
          postMessage: (data: any) => void;
        };
      };
    };
  }
}

interface JavascriptCallback {
  gaTagging(data: any): any;
}
declare const historicalChart: JavascriptCallback;

const Search = (props: SearchProps) => {
  const {
    setShowComponents,
    onSearch,
    onSelected,
    valueSearch,
    scrollProp,
    bondType,
  } = props;
  const [isShowSearchList, setIsShowSearchList] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [warning, setWarning] = useState<string | undefined>(undefined);
  const [isShowIconClear, setIsShowIconClear] = useState(false);
  const [eventInputSearch, setEventInputSearch] = useState<any>(undefined);

  const onCloseSearch = useCallback(
    (e) => {
      document.body.style.overflow = "visible";
      e.stopPropagation();
      setSearchValue("");
      onSearch?.("");
      setIsShowSearchList(false);
      setIsShowIconClear(false);
      setShowComponents(true);
      setWarning(undefined);
    },
    [
      setSearchValue,
      setShowComponents,
      setIsShowSearchList,
      setIsShowIconClear,
      setWarning,
      onSearch,
    ]
  );

  const onClearSearchValue = useCallback(
    (e) => {
      e.stopPropagation();
      setSearchValue("");
      onSearch?.("");
      setIsShowIconClear(false);
      setWarning(undefined);
    },
    [setSearchValue, setIsShowIconClear, setWarning, onSearch]
  );

  const onClickListItem = useCallback(
    (name: string) => {
      document.body.style.overflow = "visible";
      onSearch?.("");
      onSelected?.(name);
      setIsShowSearchList(false);
      setSearchValue("");
      setShowComponents(true);
      setIsShowIconClear(false);

      const serviceName =
        bondType === "CB"
          ? "CBWalletYTMGraph"
          : bondType === "SB"
          ? "BondBuySell2ndYTMGraph"
          : "";
      const data =
        bondType === "CB"
          ? {
              screenName: serviceName,
              category: serviceName,
              action: "Click",
              label: `${serviceName}_Select${name}`,
            }
          : bondType === "SB"
          ? {
              screenName: serviceName,
              category: serviceName,
              action: "Click",
              label: `${serviceName}_Select`,
              ptInformation: name,
            }
          : {};

      const body = JSON.stringify(data);
      const campaignWebIOS = "historicalChart";
      const ua = navigator.userAgent.toLowerCase();
      const isAndroid = ua.indexOf("android") > -1;
      try {
        if (isAndroid) {
          let android = historicalChart;
          android.gaTagging(body);
        } else if (window.webkit && window.webkit.messageHandlers) {
          window.webkit.messageHandlers[campaignWebIOS].postMessage(body);
        } else {
          console.log("window.webkit.messageHandlers is undefined");
        }
      } catch (error) {
        console.log("ga error", error);
      }

      ReactGA.event({
        category: serviceName,
        action: "Click",
        label: `${serviceName}_Select${name}`,
      });

      ReactGA.pageview(
        `${window.location.pathname}${window.location.search}`,
        [],
        serviceName
      );
    },
    [
      onSearch,
      onSelected,
      setSearchValue,
      setShowComponents,
      setIsShowSearchList,
      setIsShowIconClear,
      bondType,
    ]
  );

  const onSearchChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setSearchValue(newValue);

      if (newValue.length > 50) {
        setWarning(
          bondType === "SB"
            ? "ไม่สามารถค้นหาพันธบัตรที่ชื่อยาวเกิน 50 ตัวอักษรได้"
            : "ไม่สามารถค้นหาหุ้นที่ชื่อยาวเกิน 50 ตัวอักษรได้"
        );
      } else {
        setWarning(undefined);
      }

      if (newValue !== "") {
        setIsShowIconClear(true);
        onSearch?.(newValue);
      }
    },
    [setSearchValue, setIsShowIconClear, setWarning, onSearch, bondType]
  );

  const onSearchClick = useCallback(
    (value) => {
      document.body.style.overflow = "hidden";
      setIsShowSearchList(true);
      setShowComponents(false);
      setSearchValue(value);
      if (value !== "") {
        setIsShowIconClear(true);
      }
    },
    [setSearchValue, setShowComponents, setIsShowSearchList, setIsShowIconClear]
  );

  const onKeyDown = useCallback(
    (e) => {
      setEventInputSearch(e);
      if (e.key === "Enter") {
        e.target.blur();
      }
    },
    [setEventInputSearch]
  );

  const onFocus = useCallback(
    (e) => {
      setEventInputSearch(e);
    },
    [setEventInputSearch]
  );

  return (
    <>
      <StyleSearchContainer>
        <FlexRow>
          <StyleSearch
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onClick={onSearchClick.bind(null, searchValue)}
            onChange={onSearchChange}
            fullWidth
            placeholder={
              bondType === "SB" ? "ค้นหาพันธบัตรตลาดรอง" : "ค้นหาหุ้นกู้ตลาดรอง"
            }
            value={searchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} alt="Search" />
                </InputAdornment>
              ),
              endAdornment: isShowIconClear && (
                <InputAdornment position="end">
                  <IconButton onClick={onClearSearchValue}>
                    <img src={clearIcon} alt="Clear" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {isShowSearchList && (
            <StyleLink onClick={onCloseSearch}>
              <Text>ยกเลิก</Text>
            </StyleLink>
          )}
        </FlexRow>

        {warning && (
          <FlexRow>
            <Text color={"red"} size={"0.75rem"}>
              {warning}
            </Text>
          </FlexRow>
        )}
      </StyleSearchContainer>
      {isShowSearchList && (
        <ListSearch
          bondType={bondType}
          list={valueSearch}
          onClickItem={onClickListItem}
          scrollProp={scrollProp}
          eventSearch={eventInputSearch}
        />
      )}
    </>
  );
};

export default Search;
