import { GRADIENT_PRIMARY } from "../../../constants/colors";
import { Chip } from "@mui/material";
import styled from "styled-components";

export const StyledChip = styled(Chip)<{
  height: number;
  fontSize: string;
  padding: string;
}>`
  background-image: ${GRADIENT_PRIMARY};
  font-style: normal;
  font-weight: 600;
  height: ${({ height }) => height.toString() + "px !important"};

  & .MuiChip-label {
    color: white;
    font-size: ${({ fontSize }) => fontSize};
    line-height: 1.688rem;
    padding-left: ${({ padding }) => padding};
    padding-right: ${({ padding }) => padding};
  }
`;
